<template>
  <Container :title="$t('onboarding.editCompany')">
    <CompanyForm @next="success"/>
  </Container>
</template>
<script>
import CompanyForm from "../components/CompanyForm"

import Vue from 'vue'

export default Vue.extend({
  name: "CompanyEdit",
  components: {
    CompanyForm,
  },
  methods: {
    success() {
      this.$router.push({ name: 'Personal' })
    },
  },
})
</script>
